import "./styles/normalize.css";

import * as React from "react";

import { StatsigClient } from "@statsig/js-client";
import { StatsigProvider } from "@statsig/react-bindings";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { LocaleProvider } from "translations";

import { ConsentManager } from "apps-common/components/ConsentManager";
import { AuthProvider } from "apps-common/context/auth";
import { resetAllSlices } from "apps-common/store";
import { useInitAnalytics } from "apps-common/utils/analytics";
import { initSentry } from "apps-common/utils/sentry";
import { theme } from "ui/themes/signup";

import { Router } from "./router";
import { routes } from "./routes";
import { GlobalStyles } from "./styles/global";

const SS_CLIENT_SDK_KEY: string = import.meta.env.VITE_SS_CLIENT_SDK_KEY;
const ENV = import.meta.env.MODE;

const queryClient = new QueryClient();

const statsigClient = new StatsigClient(
  SS_CLIENT_SDK_KEY,
  {},
  { environment: { tier: ENV } },
);
await statsigClient.initializeAsync();

const Main = () => {
  useInitAnalytics("hub");

  return (
    <React.StrictMode>
      <StatsigProvider client={statsigClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <QueryClientProvider client={queryClient}>
            <AuthProvider
              onLogout={(moiFlag: boolean, moiLogoutUrl?: string): void => {
                resetAllSlices();
                sessionStorage.removeItem("paymentMethodId");
                sessionStorage.removeItem("zuoraResponse");
                if (moiFlag && moiLogoutUrl) {
                  window.location.replace(moiLogoutUrl);
                } else {
                  const currentPath =
                    window.location.pathname === "/login"
                      ? "/"
                      : window.location.pathname;

                  const hubNextPath =
                    currentPath === routes.updatePaymentMethod
                      ? routes.paymentDetailsPageSelectAddressUsage
                      : currentPath;
                  window.location.replace("/login/?next=" + hubNextPath);
                }
              }}
              onLogin={(): void => {
                window.location.replace("/");
              }}
            >
              <LocaleProvider>
                <Router />
                <ConsentManager />
              </LocaleProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ThemeProvider>
      </StatsigProvider>
    </React.StrictMode>
  );
};

initSentry();

ReactDOM.createRoot(document.getElementById("root")!).render(<Main />);

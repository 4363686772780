import { useEffect } from "react";

import isEqual from "lodash/isEqual";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { t } from "translations";

import { useGetAccount } from "apps-common/hooks/useGetAccount";
import { useGetSellToCountries } from "apps-common/hooks/useSellToCountries";
import { BillingPeriod } from "apps-common/types";
import { track } from "apps-common/utils/analytics";
import { throwError } from "apps-common/utils/errorHandler";
import { removeSpaces } from "apps-common/utils/helpers";
import { Header } from "ui";
import { Button } from "ui/components/Button";
import { Loader } from "ui/components/Loader";
import { MainContainer } from "ui/styles/containers";
import { Paragraph } from "ui/styles/text";
import { media } from "ui/themes/signup";

import {
  NavigationRow,
  PreviewRow,
  SectionCard,
} from "../components/SectionCard";
import { useRenewSubscription } from "../hooks/useRenewSubscription";
import { routes } from "../routes";
import { canUseExistingAddressForPaymentMethod } from "../utils/address";
import {
  getCurrentPaymentMethod,
  paymentMethodDetail,
} from "../utils/paymentMethod";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  align-items: center;

  ${media.large`
     margin-top: 60px;
     align-items: flex-start;

     > p {
     margin-left: 0;
     }
  `}
`;

export const ReviewPaymentMethodForRenew = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const {
    data: accountData,
    isFetching: isFetchingAccount,
    isError: isGetAccountError,
    error: getAccountError,
  } = useGetAccount();

  const {
    data: countryData,
    isFetching: isFetchingCountries,
    isError: isErrorGetCountries,
    error: errorInGetCountries,
  } = useGetSellToCountries(!!accountData?.account);

  const mutation = useRenewSubscription();
  const {
    mutate,
    error: renewError,
    isError: isRenewError,
    isSuccess: isRenewSuccess,
    isPending: isRenewPending,
  } = mutation;

  // In case of mutation is success we invalidate account data so it's refreshed on frontpage
  useEffect(() => {
    if (isRenewSuccess) {
      navigate(routes.updatePaymentMethodSuccessRenew);
    }
  }, [isRenewSuccess, navigate]);

  if (isFetchingAccount || isRenewPending || isFetchingCountries) {
    return (
      <>
        <Header
          appType="hub"
          pageType="create"
          title={t("membership_signup_payment_method_title")}
          onBackClick={() => navigate(routes.index)}
        />
        <MainContainer $padding="10px 0" $maxWidth="794px">
          <Loader />
        </MainContainer>
      </>
    );
  }

  if (isGetAccountError || !accountData) {
    throw throwError("errorOnGetAccount", getAccountError);
  }

  if (isRenewError) {
    throw throwError("errorOnRenewSubscription", renewError);
  }

  if (isErrorGetCountries) {
    throw throwError("errorOnGettingCountries", errorInGetCountries);
  }

  const currentPaymentMethod = getCurrentPaymentMethod(
    accountData.account.paymentMethods,
  );

  const { billToContact, shipToContact } = accountData.account;

  // users without address can end up in review payment renew page
  const { address1, city, country, postalCode, state } = billToContact ?? {};
  const {
    address1: homeAddress1,
    city: homeCity,
    country: homeCountry,
    postalCode: homePostCode,
    state: homeState,
  } = shipToContact ?? {};

  const BILLING_STRING_ADDRESS = billToContact
    ? `${address1}, ${city}, ${
        state
          ? intl.formatMessage({
              id: `state_${country}_${removeSpaces(state)}`,
            })
          : ""
      } ${postalCode}, ${intl.formatMessage({ id: `country_${country}` })}`
    : t("membership_hub_no_address");

  const HOME_STRING_ADDRESS = shipToContact
    ? `${homeAddress1}, ${homeCity}, ${
        homeState
          ? intl.formatMessage({
              id: `state_${homeCountry}_${removeSpaces(homeState)}`,
            })
          : ""
      } ${homePostCode}, ${intl.formatMessage({ id: `country_${homeCountry}` })}`
    : t("membership_hub_no_address");

  const homeAddressValue = isEqual(billToContact, shipToContact)
    ? t("membership_hub_same_as_billing_address")
    : HOME_STRING_ADDRESS;

  const paymentDetails = paymentMethodDetail(currentPaymentMethod);

  const currentSubscription = accountData.account.currentSubscription;
  if (!currentSubscription) {
    // Should never happen, as at this point user should have a subscription
    throw throwError("hubGeneralError", "No subscription found for user.");
  }

  const billingPeriod =
    currentSubscription.recurringFee?.billingPeriod ?? BillingPeriod.Months;

  // No address or invalid address are sent to Address page directly
  const isExistingAddressValid =
    !!(shipToContact && billToContact) &&
    canUseExistingAddressForPaymentMethod(
      shipToContact,
      billToContact,
      countryData,
    );

  // isReusableInRenew already handles if the payment is active. If no PM then there is no currentPaymentMethod object
  const isDirectRenewPossible =
    currentPaymentMethod?.isReusableInRenew && isExistingAddressValid;

  // takes user who cant do direct renew to either address or select address page based on address validation
  const nextRouteToRenewForNoDirectRenewCase = isExistingAddressValid
    ? routes.paymentDetailsPageSelectAddressUsage
    : routes.addressForm;

  const renewSubscription = () => {
    track({
      event: "CTA Clicked",
      payload: {
        cta: "submit",
        action: "renew_membership",
      },
    });
    mutate(billingPeriod);
  };
  const renewWithAddressAndPaymentMethod = () => {
    // go straight to address & payment page as user's data is corrupted to renew directly
    track({
      event: "CTA Clicked",
      payload: {
        cta: "next",
        action: "renew_next_step",
        step: "review_payment",
      },
    });
    navigate(nextRouteToRenewForNoDirectRenewCase);
  };

  return (
    <>
      <Header
        appType="hub"
        pageType="create"
        title={t("membership_signup_payment_method_title")}
        onBackClick={() => navigate(routes.index)} // todo: nice to have - renew can also be accesed from membership overview page, make it dynamic
      />
      <MainContainer $padding="10px 0" $maxWidth="794px">
        <SectionCard>
          <PreviewRow
            label={t("membership_hub_payment_details")}
            rowValues={paymentDetails}
          />
          <PreviewRow
            label={t("membership_hub_billing_address")}
            rowValues={[{ value: BILLING_STRING_ADDRESS, color: "grayLight" }]}
          />
          <PreviewRow
            label={t("membership_home_address")}
            rowValues={[{ value: homeAddressValue, color: "grayLight" }]}
          />
          <NavigationRow
            label={intl.formatMessage({
              id: "membership_hub_renew_with_payment",
            })}
            href={nextRouteToRenewForNoDirectRenewCase}
            onClick={() => {
              track({
                event: "CTA Clicked",
                payload: {
                  cta: "next",
                  action: "renew_next_step",
                  step: "review_payment",
                },
              });
            }}
          />
        </SectionCard>
        <Container>
          {/* always render direct renew btn. It handles the next route based on user data*/}
          <Button
            onClick={
              isDirectRenewPossible
                ? renewSubscription
                : renewWithAddressAndPaymentMethod
            }
          >
            {t("membership_hub_renew_submit")}
          </Button>
          <Paragraph
            $fontSize="xsmall"
            $fontWeight="400"
            $lineHeight="16px"
            $color="grayLight"
            $textAlign="justify"
            $margin={"20px 24px 0 24px"}
          >
            {t("membership_signup_payment_disclaimer")}
          </Paragraph>
        </Container>
      </MainContainer>
    </>
  );
};

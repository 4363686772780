import { Contact, CountryWeSellTo } from "apps-common/types";

// must be home address not billing address for checking tax in making PM
export const isExistingStateValid = (
  homeCountry: string | undefined,
  homeState: string | undefined | null,
) => {
  const stateRequiredCountries = ["US", "CA", "AE", "JP"];
  const isStateRequiredCountry = stateRequiredCountries.includes(
    homeCountry ?? "",
  );
  return isStateRequiredCountry ? !!homeState : true;
};

// if the existing address is manually verified but missing state ex) AE, then the user needs to contact MX to update the address
export const canUseExistingAddressForPaymentMethod = (
  homeContact: Contact | null,
  billingContact: Contact | null,
  countryData?: CountryWeSellTo[],
): boolean => {
  if (!homeContact || !billingContact) {
    return false;
  }

  const validCountry = !!countryData?.some(
    (country) => country.countryCode === homeContact.country,
  );
  const validState = isExistingStateValid(
    homeContact.country,
    homeContact.state,
  );

  return validCountry && validState && homeContact.addressValidated;
};

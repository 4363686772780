import { useState } from "react";

import isEqual from "lodash/isEqual";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  AddressForm,
  AddressFormProps,
} from "apps-common/components/AddressForm";
import {
  FormValues,
  emptyForm,
  emptyAddress,
} from "apps-common/components/AddressForm/types";
import { useAddressTaxValidation } from "apps-common/components/AddressForm/useAddressTaxValidation";
import { AddressSuggestionModal } from "apps-common/components/AddressSuggestionModal";
import { useGetAccount } from "apps-common/hooks/useGetAccount";
import { useGetSellToCountries } from "apps-common/hooks/useSellToCountries";
import { Address, BillingPeriod, Problem } from "apps-common/types";
import {
  convertAddressStateNameTo2CharCode,
  convertAddressToContactForValidation,
  convertContactToAddress,
  postGridEnabledCountries,
} from "apps-common/utils/address";
import { track } from "apps-common/utils/analytics";
import { throwError } from "apps-common/utils/errorHandler";
import { useFlag, Flags } from "apps-common/utils/featureFlags";
import { getRatePlan } from "apps-common/utils/getProduct";
import { removeEmptyStringOrNilProperties } from "apps-common/utils/helpers";
import { logger } from "apps-common/utils/logger";
import { Header, Loader } from "ui";
import { MainContainer } from "ui/styles/containers";

import { routes } from "../routes";
import { useStore } from "../store";
import { canUseExistingAddressForPaymentMethod } from "../utils/address";
import { isExpiredSubscription, isRenewAllowed } from "../utils/member";

const useGetDefaultValues = () => {
  const { data: accountData } = useGetAccount();
  const userAddressForm = useStore((state) => state.userAddressForm);
  const isExpiredSub = isExpiredSubscription(
    accountData?.account?.currentSubscription?.subscriptionState,
  );

  // For billing: Default to client state and fallback to account data if found
  const billingCountry =
    userAddressForm?.billingAddress?.country ??
    accountData?.account.billToContact?.country ??
    "";

  // For shipping, it's more complicated:
  let shippingCountry = "";
  if (!isExpiredSub) {
    // If the sub is active then it can't be changed, so default to account data and fallback to state
    shippingCountry =
      accountData?.account.shipToContact?.country ??
      userAddressForm?.shippingAddress?.country ??
      "";
  } else {
    // But if the sub is expired, default to state (so we don't mess with possible changes in state) and fallback to account data
    shippingCountry =
      userAddressForm?.shippingAddress?.country ??
      accountData?.account.shipToContact?.country ??
      "";
  }

  // No need to restrict the shipping country selection if:
  // 1. the sub is expired or
  // 2. user don't have previous shipping country (this covers placeholder accounts)
  if (isExpiredSub || !shippingCountry) {
    return userAddressForm ?? emptyForm;
  }

  let isUnifiedAddress = true;
  if (userAddressForm?.isUnifiedAddress === false) {
    // Frontend state is toggled to false, use that
    isUnifiedAddress = false;
  } else {
    isUnifiedAddress = billingCountry === shippingCountry;
  }

  return {
    ...(userAddressForm ?? emptyForm),
    billingAddress: {
      ...(userAddressForm?.billingAddress ?? emptyAddress),
      country: billingCountry,
    },
    shippingAddress: {
      ...(userAddressForm?.shippingAddress ?? emptyAddress),
      country: shippingCountry,
    },
    isUnifiedAddress,
  };
};

export const AddressPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [showAddressSuggestionModal, setShowAddressSuggestionModal] =
    useState(false);
  const [problems, setProblems] = useState<Problem[]>([]);
  const setRatePlan = useStore((state) => state.setRatePlan);
  const setUserAddressForm = useStore((state) => state.setUserAddressForm);
  const setBillingCountryIsoAlpha3Code = useStore(
    (state) => state.setBillingCountryIsoAlpha3Code,
  );
  const addressvalidationSuggestionsFlag = useFlag(
    Flags.ADDRESS_VALIDATION_SUGGESTIONS,
  );
  const {
    data: accountData,
    error: errorInGetAccount,
    isError: isErrorInGetAccount,
    isFetching: isFetchingAccountData,
  } = useGetAccount();

  const {
    data: countryData,
    isFetching: isFetchingCountries,
    isError: isErrorGetCountries,
    error: errorInGetCountries,
  } = useGetSellToCountries(!!accountData?.account);

  if (isErrorInGetAccount || !accountData) {
    throw throwError("errorOnGetAccount", errorInGetAccount);
  }

  if (isErrorGetCountries || !countryData) {
    throw throwError("errorOnGettingCountries", errorInGetCountries);
  }

  const { shipToContact, billToContact, currentSubscription, email } =
    accountData.account;

  if (!currentSubscription) {
    // Should never happen, as at this point user should have a subscription
    throw throwError("hubGeneralError", "No subscription found for user.");
  }

  const isRenewFlow = isRenewAllowed(currentSubscription.renewalState);
  const isExistingAddressValid = canUseExistingAddressForPaymentMethod(
    shipToContact,
    billToContact,
    countryData,
  );

  // handling next and back routes
  const renewBackRoute = isExistingAddressValid
    ? routes.paymentDetailsPageSelectAddressUsage
    : routes.reviewPaymentMethodForRenew;
  const updatePaymentBackRoute = isExistingAddressValid
    ? routes.paymentDetailsPageSelectAddressUsage
    : routes.index;
  const handleBackClick = () => {
    return isRenewFlow
      ? navigate(renewBackRoute)
      : navigate(updatePaymentBackRoute);
  };

  const nextPage = isExpiredSubscription(currentSubscription.subscriptionState)
    ? routes.selectPlan
    : routes.updatePaymentMethod;

  const header = (
    <Header
      appType="hub"
      pageType="create"
      title={intl.formatMessage({
        id: "membership_signup_billing_address_title",
      })}
      subTitle={intl.formatMessage({
        id: "membership_hub_address_page_sub_text",
      })}
      onBackClick={handleBackClick}
    />
  );

  const values = useGetDefaultValues();
  const formMethods = useForm<FormValues>({
    values,
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
    criteriaMode: "all",
    mode: "onTouched",
  });
  const { mutateAsync: addressValidationMutate } =
    useAddressTaxValidation(formMethods);

  if (isFetchingCountries || isFetchingAccountData) {
    return (
      <>
        {header}
        <MainContainer>
          <Loader />
        </MainContainer>
      </>
    );
  }

  // only allowed EXPIRED subscription users or users without recurringFee (placeholders)
  const isCountryChangeAllowed =
    isExpiredSubscription(currentSubscription.subscriptionState) ||
    !currentSubscription.recurringFee; // Typewise recurringfee is safer to check
  const selectProduct = (country: string) => {
    const ratePlan =
      !isExpiredSubscription(currentSubscription.subscriptionState) &&
      currentSubscription.recurringFee
        ? currentSubscription.recurringFee
        : getRatePlan(
            accountData.membershipOffering.products,
            country,
            BillingPeriod.Months,
          );
    ratePlan && setRatePlan(ratePlan);
  };

  const skipValidation = (shippingAddress: Address): boolean => {
    // Skip address validation if manually verified
    const userInput = removeEmptyStringOrNilProperties(shippingAddress);
    const verifiedAddressFromServer =
      shipToContact &&
      convertAddressStateNameTo2CharCode(
        convertContactToAddress(shipToContact),
        countryData,
      );

    return (
      !!shipToContact?.addressValidated &&
      isEqual(userInput, verifiedAddressFromServer)
    );
  };

  const onSubmit: AddressFormProps["onSubmit"] = async (data) => {
    setBillingCountryIsoAlpha3Code(data.billingAddress.isoAlpha3Code ?? "");
    setUserAddressForm({
      shippingAddress: removeEmptyStringOrNilProperties(
        data.shippingAddress,
      ) as Address,
      billingAddress: removeEmptyStringOrNilProperties(
        data.billingAddress,
      ) as Address,
    });

    // Set product and navigate to next page if tax validation is success
    const onValidationSuccess = () => {
      selectProduct(data.shippingAddress.country);
      track({
        event: "Address Added",
      });
      logger.info("Address added, continuing to next page", { nextPage });
      navigate(nextPage);
    };

    if (skipValidation(data.shippingAddress)) {
      onValidationSuccess();
    } else {
      const userInputHomeAddressToValidate =
        convertAddressToContactForValidation(data.shippingAddress, email);

      // Always use shipping address for address validation for tax calculation
      await addressValidationMutate(userInputHomeAddressToValidate, {
        onSuccess: ({ success, problems }) => {
          if (success) {
            onValidationSuccess();
          } else {
            if (
              postGridEnabledCountries.includes(
                userInputHomeAddressToValidate.country,
              ) &&
              problems.some((problem) => problem.correctedValue) &&
              addressvalidationSuggestionsFlag
            ) {
              setShowAddressSuggestionModal(true);
            }
            setProblems(problems);
            const filteredProblems = problems.map(
              ({ correctedValue, ...rest }) => rest,
            );
            logger.warn("Address Validation Failed", {
              problems: filteredProblems,
              country: userInputHomeAddressToValidate.country,
            });
            // Track address validation failures
            track({
              event: "Address Validation Failed",
              payload: {
                reason: "INVALID_ADDRESS",
                problems: filteredProblems,
                country: userInputHomeAddressToValidate.country,
              },
            });
          }
        },
      });
    }
  };

  return (
    <>
      {showAddressSuggestionModal &&
        formMethods.getValues("shippingAddress") && (
          <AddressSuggestionModal
            showModal={showAddressSuggestionModal}
            setShowModal={setShowAddressSuggestionModal}
            isUnifiedAddress={formMethods.getValues("isUnifiedAddress")}
            currentAddress={
              // Note: shippingAddress is not yet copied to shippingAddress at this point
              formMethods.getValues("isUnifiedAddress")
                ? formMethods.getValues("billingAddress")
                : formMethods.getValues("shippingAddress")
            }
            setAddressFormData={formMethods.setValue}
            problems={problems}
            clearErrors={formMethods.clearErrors}
          />
        )}
      {header}
      <MainContainer>
        <FormProvider {...formMethods}>
          <AddressForm
            onSubmit={onSubmit}
            onSubmitClick={() => {
              isRenewFlow
                ? track({
                    event: "CTA Clicked",
                    payload: {
                      cta: "next",
                      action: "renew_next_step",
                      step: "billing_address",
                    },
                  })
                : track({
                    event: "CTA Clicked",
                    payload: {
                      cta: "next",
                      action: "update_pm_next_step",
                      step: "billing_address",
                    },
                  });
            }}
            countries={countryData}
            allowCountryChange={isCountryChangeAllowed}
          />
        </FormProvider>
      </MainContainer>
    </>
  );
};

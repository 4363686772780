import { ReactNode } from "react";

import { t } from "translations";

import {
  PaymentMethod,
  PaymentMethodState,
  PaymentMethodType,
  RenewalState,
} from "apps-common/types";
import { CreditCardIcon } from "ui/components/Icons/CardPayment";
import { LinkTextColor } from "ui/components/LinkSection";

import { routes } from "../routes";

import { getPaymentMethodStatus, isRenewAllowed } from "./member";

type PaymentDetailTextColor = "grayLight" | "lightRed";
interface PaymentDetail {
  value: ReactNode;
  color: PaymentDetailTextColor;
  icon?: ReactNode;
}

export const formatCardNumber = (cardNumber: string): string => {
  // Add spaces between every 4 characters: "********4111" => "**** **** 4111"
  const match = cardNumber.match(/.{1,4}/g);
  if (!match) return "";
  return match.join(" ");
};

export const paymentMethodDetail = (
  paymentMethod: PaymentMethod | undefined,
): PaymentDetail[] => {
  if (!paymentMethod) {
    return [
      {
        value: t("membership_hub_no_payment_method"),
        color: "lightRed",
      },
    ];
  }
  const paymentMethodState = getPaymentMethodStatus(paymentMethod);
  const paymentDetailsText =
    paymentMethod.type === PaymentMethodType.CreditCard
      ? formatCardNumber(paymentMethod.cardNumber)
      : "PayPal";
  const paymentIcon =
    paymentMethod.type === PaymentMethodType.CreditCard ? (
      <CreditCardIcon cardType={paymentMethod.cardType} />
    ) : null;

  const paymentDetails: PaymentDetail[] = [
    {
      value: paymentDetailsText,
      color: "grayLight" as PaymentDetailTextColor,
      icon: paymentIcon,
    },
  ];

  if (paymentMethodState === PaymentMethodState.EXPIRED) {
    paymentDetails.push({
      value: "Expired",
      color: "lightRed" as PaymentDetailTextColor,
    });
  }

  return paymentDetails;
};

interface PaymentMethodCTAText {
  lokaliseLabelKeyName: string;
  track: { cta: string };
  href: string;
  color?: LinkTextColor;
}

export const getToPaymentPageCTAText = (
  renewalState: RenewalState,
  paymentMethod: PaymentMethod | undefined,
  isExistingAddressValid: boolean,
): PaymentMethodCTAText => {
  const isRenewFlow = isRenewAllowed(renewalState);

  // User with invalid address will always end up in the address page to update PM. For renew, reviewPaymentMethodForRenew page handles that for the invalidAddress user
  const toPaymentMethodPage = isRenewFlow
    ? routes.reviewPaymentMethodForRenew
    : isExistingAddressValid
      ? routes.paymentDetailsPageSelectAddressUsage
      : routes.addressForm;

  if (isRenewFlow) {
    return {
      lokaliseLabelKeyName: "membership_hub_renew_membership",
      track: { cta: "renew_membership" },
      href: toPaymentMethodPage,
      color: "bannerErrorState",
    };
  }

  // normal flow
  const PaymentMethodLinkText = paymentMethod
    ? "membership_hub_view_payment_details_page"
    : "membership_hub_add_payment_method";
  return {
    lokaliseLabelKeyName: PaymentMethodLinkText,
    track: paymentMethod
      ? { cta: "view_payment" }
      : { cta: "add_payment_method" },
    href: toPaymentMethodPage,
    color: "blueLight",
  };
};

// not all users have payment method
export const getCurrentPaymentMethod = (
  paymentMethods: PaymentMethod[] | [],
): PaymentMethod | undefined =>
  paymentMethods.find((pm) => pm.isDefault) ?? paymentMethods[0];
